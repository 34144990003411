<template>
    <div>
        <div class="content-container sm:mt-0 desktop-cart radius-anchor checkout-box">
            <modal-cancel-transaction-timer />
            <div class="pb-5 relative">
                <!-- <div class="mb-5 p-3 alert-warning rounded-sm" v-if="delivery.minimum > transaction.subtotal">Let op! Minimum bestelbedrag is <currency/> {{ delivery.minimum }}</div> -->
                <h2 class="text-2xl font-bold ">{{ $t('shoppingcart.title') }}</h2>

                <div v-if="config.theme.cart_style === 'cart-fixed'" class="fixed-cart-close" @click="cartToggle()">
                    +
                </div>
            </div>
            <!-- BASKET -->
            <!-- basket items -->
            <div>

                <p v-if="area_busy" class="mb-2 text-red-500">{{ $t('notifications.area.busy') }}</p>
                <div class="mx-5 mb-2 p-3 alert-warning rounded-sm"
                    v-if="transaction.lines.length > 0 && location.delivery && location.delivery.minimum > transaction.subtotal">
                    {{ $t('notifications.delivery.minimumamount') }} {{ $n(location.delivery.minimum, currency) }}
                </div>
                <div class="ccss--modal-shopping-cart-content">


                    <div>
                        <div v-if="transaction.lines.length > 0" class="overflow-auto cart-items-wrapper">
                            <div class="" v-for="(line, index) in transaction.lines" v-bind:key="index">
                                <div v-if="!line.line_is_fee" class="flex justify-start">
                                    <div class="w-full">
                                        <div class="flex justify-start items-start">
                                            <!-- Quantity -->
                                            <div class="flex justify-between items-start flex-grow flex-col mr-3 mb-3"
                                                 :class="{'ml-4': line.addon}"
                                            >
                                                <div class="text-left">
                                                    <div class="text-sm leading-5 font-bold product-name mb-1"
                                                        >{{ line.description }}
                                                    </div>
                                                    <div class="italic text-xs my-2"
                                                        v-for="(kitchen_grocery, grocery_index) in line.kitchen_groceries"
                                                        v-bind:key="grocery_index">
                                                        {{ kitchen_grocery.description }}: {{ kitchen_grocery.value }}
                                                    </div>

                                                </div>
                                                <div v-if="!line.addon || (line.addon && line.total !== 0)">
                                                    <div v-if="line.default_item && line.total == 0" class="text-sm product-price">{{ $t('shoppingcart.free') }}</div>
                                                    <div v-else class="product-price">
                                                        <template v-if="line.total == line.total_without_discount">
                                                            {{ $n(line.total, currency) }}
                                                        </template>
                                                        <template v-else>
                                                            <div class="flex text-sm">
                                                                <div class="text-sm">
                                                                    {{ $n(line.total, currency) }}
                                                                </div>
                                                                <span class="text-red-500 text-xs line-through italic ml-3">
                                                                    {{ $n(line.total_without_discount, currency) }}
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="flex rounded-sm justify-center items-center add-item-wrapper"
                                                :class="{'justify-end' : edit === false}"
                                            >
                                                <div v-if="line.editable === true">
                                                    <button v-if="line.quantity > 1 && edit === true" type="button"
                                                            @click="itemQuantitySubtract(line)" class="btn p-1 plus-minus">
                                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                                        </svg>
                                                    </button>
                                                    <button v-else-if="edit === true" type="button" @click="itemQuantitySubtract(line)"
                                                            class="btn p-1 trash">
                                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div class="item-quantity px-3 text-xs font-bold">
                                                    {{ line.quantity }}
                                                </div>
                                                <div v-if="line.editable === true && edit === true">
                                                    <button :disabled="line.quantity == line.quantity_max" type="button"
                                                            @click="itemQuantityAdd(line)" class="btn p-1 plus-minus">
                                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="acceptTip">
                                <div class="cart-tip-section" v-if="tip === true && edit === true">
                                <div class="pb-2 mb-3">
                                    <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                                        <h3 class="ml-2 mt-2 text-lg leading-6">
                                            {{ $t('order.tip') }}
                                        </h3>
                                    </div>
                                </div>

                                <div class="mt-1 flex justify-start items-center gap-2 overflow-x-auto" id="tip-list">
                                    <!-- {{ selectedTip }} -->
                                    <div v-for="(tip_value, index) in this.config.settings.tip.values.split(';')" :key="index"
                                        @click="setTip(tip_value)" class="btn btn-small checkout-option basket-tip"
                                        :class="{'tip-selected': tip_value == selectedTip.value && !tipCustom}">
                                        <span v-if="this.config.settings.tip.type == 'amount'"> {{ $n(parseFloat(tip_value), currency) }}</span>
                                        <span v-if="this.config.settings.tip.type == 'percentage'">{{ tip_value }} %</span>
                                        <span v-if="tip_value == selectedTip.value" class="remove-tip">+</span>

                                    </div>
                                    <div @click="processCustomTipFrontEnd()" class="btn btn-small checkout-option basket-tip"
                                         :class="{'tip-selected': tipCustom && selectedTip.value > 0}"
                                    >
                                        {{ $t('tip.custom.other') }}
                                        <span v-if="tipCustom" class="ml-2 inline-block">
                                            (
                                                <span v-if="this.config.settings.tip.type == 'amount'">{{ $n(parseFloat(tip_value), currency) }}</span>
                                                <span v-if="this.config.settings.tip.type == 'percentage'">{{ formattedTipValue }} %</span>
                                            )
                                        </span>
                                        <span v-if="tipCustom === true" class="remove-tip">+</span>

                                    </div>
                                </div>
                                <div v-if="custom_tip_show === true" class="mt-4 flex justify-start items-center gap-2">
                                    <!-- {{ select custom tip }} -->
                                    <input type="number" v-model="custom_tip_amount" name="custom_tip_amount" step=".01"
                                        class="font-medium radius-anchor flex-grow w-0" :placeholder="$t('order.tip')">
                                    <div @click="setCustomTip()" class="btn btn-small"> {{ $t('tip.custom.add') }}</div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div v-else>
                            {{ $t('shoppingcart.empty') }}
                        </div>
                    </div>
                    <div :class="{'bg-shoppingcarttotals': type != 'modal'}" v-if="transaction.lines.length > 0">
                        <div class="mb-3 border border-b"></div>
                        <div class="pb-3">
                            <div class="flex justify-between text-xs font-medium">
                                <div class="">{{ $t('fields.subtotal') }}</div>
                                {{ $n(this.getSubTotal, currency) }}
                            </div>

                            <div v-if="!getDeliveryFee === false" class="flex justify-between text-xs font-medium pl-2">
                                <div>{{ $t('shoppingcart.delivery_fee')}}</div>
                                <div>
                                    <div v-if="getDeliveryFee === true">
                                        {{ $t('shoppingcart.free')}}
                                    </div>
                                    <div v-else>
                                        {{ $n(getDeliveryFee, currency) }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="!getTransactionFee === false && !getTransactionFee === true" class="flex justify-between text-xs font-medium text-gray-600 pl-2">
                                <div>{{ $t('shoppingcart.service_fee')}}</div>
                                <div>
                                    {{ $n(getTransactionFee, currency) }}
                                </div>
                            </div>
                            <div v-if="selectedTip.value > 0" class="flex justify-between text-xs font-medium text-gray-600 pl-2">
                                <div>
                                    {{ $t('shoppingcart.TIP')}}
                                    <span class="ml-px text-xs" v-if="selectedTip.percentage">
                                        ({{ $n(parseFloat(selectedTip.percentage), 'percentage') }} %)
                                    </span>
                                </div>
                                <div>
                                    {{ $n(selectedTip.value, currency) }}
                                </div>
                            </div>
                        </div>

                        <!-- <div v-if="transaction.discount && transaction.discount.value" class="flex justify-between text-xs font-medium pb-3">
                            <div class="">{{transaction.discount.description}}</div>
                            <div> - {{ $n((transaction.total_without_discount - transaction.total), currency) }}</div>
                        </div> -->

                        <div class="">
                            <div v-if="transaction.discount && transaction.discount.value" class="flex justify-between text-xs font-medium pb-3 mb-2 border-b-2">
                            <div class="">{{ $t('fields.total')}}</div>
                            <div> {{ $n((transaction.total_without_discount), currency) }}</div>
                            </div>

                            <div v-if="transaction.discount && transaction.discount.value" class="flex justify-between text-xs font-medium pb-3">
                            <div class="">{{transaction.discount.description}}</div>
                            <div> - {{ $n((transaction.total_without_discount - transaction.total), currency) }}</div>
                            </div>

                        </div>

                        <div class="flex justify-between font-bold">
                            <div>{{ $t('shoppingcart.total') }}</div>
                            <div>{{ $n(transaction.total, currency) }}</div>
                        </div>

                        <div v-if="transaction.vouchers.length > 0">
                            <div class="mt-2 flex justify-between">
                                <div>{{ $t('shoppingcart.total_vouchers') }}</div>
                                <div>{{ $n(transaction.total_vouchers, currency) }}</div>
                            </div>
                            <div class="flex justify-between">
                                <div>{{ $t('shoppingcart.total_payable') }}</div>
                                <div>{{ $n(transaction.total_payable, currency) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="transaction.lines.length > 0 && edit === true" class="bg-shoppingcarttotals pt-5">
                <div>
                    <a @click="submitCheckout" class="w-full inline-flex items-center justify-center w-full rounded-sm btn btn-primary text-base leading-6 font-medium shadow-sm focus:outline-none">
                        {{ $t('shoppingcart.button') }}
                        <svg class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7V9H7C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11H9V13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13V11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H11V7Z" clip-rule="evenodd"/>
                        </svg>
                    </a>
                </div>
            </div>

            <template v-if="transaction.lines.length > 0">
                <p v-if="transaction.method === 'delivery' && location.delivery && location.delivery.freefrom"
                     class="text-xs font-medium mt-2 text-right">
                    {{ $t('shoppingcart.freefrom')}}
                   <span class="font-bold"> {{ $n(location.delivery.freefrom, currency) }} </span>
                </p>
            </template>
        </div>
    </div>
</template>

<script>
import * as _api from "../lib/api";
import * as _state from "../lib/state";
import * as _location from "../lib/location";
import * as _table from "../lib/table";
import * as _transaction from "../lib/transaction";
import * as _notification from '../lib/notification';
// import * as _helpers from '../lib/helpers';

import ModalCancelTransactionTimer from './ModalCancelTransactionTimer.vue'

export default {
    name: 'Basket',
    props: ['edit', 'current_location', 'tip', 'location_slug'],
    components: {
        ModalCancelTransactionTimer
    },
    data() {
        return {
            open: false,
            custom_tip_show: false,
            custom_tip_amount: null,
            tipCustom: false,
            formattedTipValue: '',
            cancelTransactionTimer: null,

            area_busy: false,

            form: {
                paymenttype_id: '',
                customer_email: this.user ? this.user.username : '',
            },
        }
    },
    async mounted () {
        if (!this.location.slug) {
            await _location.init(this.location_slug);
        }

        await this.checkIfAreaIsBusy();
        _transaction.updateFees();
    },
    methods: {
        openModal() {
            this.modal = true
        },

        closeModal() {
            this.modal = false
        },

        cartToggle() {
            document.querySelector('.cart-fixed').classList.toggle('cart-open');
        },

        itemQuantityAdd (line) {
            _transaction.addQuantity(line);
        },

        submitCheckout () {
            let getSufficientTotalForDelivery = _transaction.getSufficientTotalForDelivery();

            if (getSufficientTotalForDelivery === true) {
                this.$router.push({ name: 'checkout.transaction', params: {location_slug: this.location.slug}});
                return;
            }
            _notification.set(this.$t('notification.order.insuficient-subtotal', {min_amount: this.$n(getSufficientTotalForDelivery, this.currency)}), 'error');
        },

        itemQuantitySubtract (line) {
            _transaction.subtractQuantity(line);
        },

        setTip (amount) {
            if (amount === this.formattedTipValue) {
                   amount = 0;
            }

            _transaction.setTip({
                cashfunction_id: this.config.settings.tip.cashfunction_id,
                type: this.config.settings.tip.type,
                amount: amount,
            });

            this.formattedTipValue = Number(amount).toFixed(2);

            this.custom_tip_show = false;
            this.tipCustom = false;
        },

        processCustomTipFrontEnd() {
            if (this.selectedTip.value > 0 && this.tipCustom === true) {
                this.setTip(0)
            }

            this.custom_tip_show = !this.custom_tip_show;

            this.custom_tip_amount = '';
        },

        setCustomTip () {
            const amount = parseFloat(this.custom_tip_amount).toFixed(2);

            if (isNaN(amount) || amount < 0.01) {
                return;
            }

            let tips = this.config.settings.tip.values.split(';');

            let exists = tips.findIndex((value) => {
                return parseFloat(value) == amount;
            });

            if (exists > 0) {
                this.setTip(parseFloat(tips[exists]))
                return;
            }

            this.setTip(amount);

            this.custom_tip_amount = '';
            this.tipCustom = true;

            setTimeout(function () {
                document.getElementById('tip-list').scrollLeft += 1000;
            }, 250); //scroll to last on customTip
        },

        async checkIfAreaIsBusy() {

            if (!this.transaction.table.id || !this.table) {
                return;
            }

            const response = await _api.post('areas/busy', {
                area_id: this.table.attributes.area_id
            });

            this.area_busy = response.data.busy;
        },
    },

    computed: {
        transaction () {
            return _state.get('transaction/getTransaction');
        },

        location () {
            return _location.get();
        },

        table () {
            return _table.get();
        },

        config () {
            return _state.get('config/getConfig');
        },

        selectedTip () {
            return _transaction.getTip();
        },

        getSubTotal () {
            return _transaction.getTotalWithoutFees(false);
        },

        getDeliveryFee () {
            return _transaction.getLineAmountById('delivery_fee');
        },

        getTransactionFee () {
            return _transaction.getLineAmountById('transaction_fee');
        },

        currency (){
            return this.config.settings.currency;
        },
        
        acceptTip(){
            return this.config.settings.tip.active;
        }
    }
}
</script>
