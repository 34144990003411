<template>
    <!-- <div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50" v-show="loading">

        <span class="opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="
            top: 50%;
        ">
            <svg class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
        </span>
    </div> -->
    <div class="checkout-wrapper max-w-xl mx-auto">
        <div class="mb-5">
            <a @click="redirectToMenu()" class="eee cursor-pointer inline-flex justify-start">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 16.7071C9.31658 17.0976 8.68342 17.0976 8.29289 16.7071L2.29289 10.7071C1.90237 10.3166 1.90237 9.68342 2.29289 9.29289L8.29289 3.29289C8.68342 2.90237 9.31658 2.90237 9.70711 3.29289C10.0976 3.68342 10.0976 4.31658 9.70711 4.70711L5.41421 9H17C17.5523 9 18 9.44772 18 10C18 10.5523 17.5523 11 17 11L5.41421 11L9.70711 15.2929C10.0976 15.6834 10.0976 16.3166 9.70711 16.7071Z" fill="currentColor"/>
                </svg>
                <div class="ml-3">{{ $t('order.button.back') }}</div>
            </a>
        </div>

        <basket :edit="true" :tip="true" />
    </div>
</template>


<script>
import axios from 'axios'

import * as _state from '../../lib/state';

import Basket from '../../components/Basket';

export default {
    name: 'CheckoutBasket',
    props: ['location_slug'],
    components: {
        Basket,
        // ModalItemsNotAvailable
    },
    data () {
        return {
            custom_tip_show: false,
            custom_tip_amount: null,
        }
    },

    async mounted() {
        // location slug
        axios.defaults.headers.common['Location'] = this.location_slug

        if (this.$route.query.reservation_id) {
            this.transaction.reservation_id = this.$route.query.reservation_id;
        }
    },


    methods: {
        redirectToMenu() {
            const routes = {
                'quickorder': 'quickorder',
                'takeaway': 'afhalen',
                'delivery': 'bezorgen',
                'tableservice': 'tafel',
                'elockers': 'lockers',
            };

            if (routes[this.transaction.method]) {
                return this.$router.push({path: '/' + this.location_slug + '/' + routes[this.transaction.method], query: this.$route.query});
            }

            return this.$router.push({name: 'landing'});
        },
    },
    computed: {

        config () {
            return _state.get('config/getConfig');
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },
    }
}
</script>
